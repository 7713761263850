@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: "Noto Sans KR";
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}